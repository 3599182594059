<template>
    <main class="gift-card-payment-result page_bg_light_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">

        <section class="gift-card-payment-result-top page_bg_grey">

            <div class="gift-card-payment-result-top-title">{{ $t("The code was successfully received") }}</div>
            <div class="gift-card-payment-result-top-image">
                <img class="" src="/assets/img/check-circle-light.svg" />
            </div>
            <div class="gift-card-payment-result-top-data">

                <div class="gift-card-payment-result-top-data-title text-bold">{{ $t("Order Number") }} {{ transactionId }}</div>
                <div v-if="transactionReceiptData" class="gift-card-payment-result-top-data-data">

                    <div class="product-name">{{ $t("Product Name") }} {{ ' ' + transactionReceiptData.product + ' ' }}</div>
                    <div class="business-name">{{ $t("Business name") }} {{ ' ' + transactionReceiptData.operationPerformer + ' ' }}</div>
                    <div class="transaction-data">{{ transactionReceiptData.transDateTime }}</div>

                </div>

            </div>

        </section>

        <section class="gift-card-payment-result-bottom">

            <div v-if="!transactionStatus || transactionStatus <= 1 || transactionStatus == 5" class="text-center">
                {{ $t("A purchase code will be updated here in the next few minutes") }}
            </div>

            <div v-else-if="transactionStatus == 3 && transactionReceiptData">

                <template v-if="transactionReceiptData.external_code">
                    <div class="">{{ $t("Purchase code") }}</div>
                    <div class="text-bold">{{ transactionReceiptData.external_code ? transactionReceiptData.external_code : '-' }}</div>

                    <div v-if="transactionReceiptData.supplier_type && transactionReceiptData.supplier_type == 6 && !(transactionReceiptData.supplier_id && transactionReceiptData.supplier_id == 1002) && transactionReceiptData.pin_code">
                        <div class="">{{ $t("Serial Number") }}</div>
                        <div class="text-bold">{{ transactionReceiptData.pin_code }}</div>
                    </div>

                    <div v-if="transactionReceiptData.supplier_id && transactionReceiptData.supplier_id == 1002 && transactionReceiptData.external_transaction_id">
                        <div class="">{{ $t("Card Code") }}</div>
                        <div class="text-bold">{{ transactionReceiptData.external_transaction_id }}</div>
                    </div>
                </template>
                <template v-else>
                    {{ $t("A purchase code will be updated here in the next few minutes") }}
                </template>

            </div>

            <div v-else-if="transactionStatus == 4 || transactionStatus == 2">
                {{ $t("Payment failed, please contact support or try again later") }}
            </div>

        </section>

        <loading :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import appConfig from '../../appConfig'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    components: {
        Loading
    },
    name: 'GiftCardPaymentResultPublic',
    data: () => {
        return {
            checkTransactionStatusInterval: null,
            checkTransactionDataInterval: null,
            loading: false
        }
    },
    mounted() {},
    destroyed () {
        this.clearCheckTransactionStatusInterval();
        this.clearCheckTransactionDataInterval();
    },
    computed: {
        ...mapState({
            transactions_receipts: state => state.game_card_payment.transactions_receipts,
            transactions_statuses: state => state.game_card_payment.transactions_statuses,
        }),
        transactionId() {
            return this.$route.params.transaction_id;
        },
        phoneNumber() {
            return this.$route.params.phone_number;
        },
        transactionReceiptData() {
            let transactionReceiptData = null;
            if (this.transactions_receipts && this.transactionId in this.transactions_receipts) {
                transactionReceiptData = {...this.transactions_receipts[this.transactionId]};
            }

            return transactionReceiptData ? transactionReceiptData : null;
        },
        price() {
            return this.transactionReceiptData.price ? this.formatNumber(this.transactionReceiptData.price) : '';
        },
        priceVAT() {
            return this.price ? this.formatNumber(this.price * 0.17) : 0;
        },
        priceWithoutVAT() {
            return this.price && this.priceVAT ? this.formatNumber(this.price - this.priceVAT) : this.price;
        },
        transactionStatusData() {
            let transactionStatusData = this.transactionId in this.transactions_statuses
                ? this.transactions_statuses[this.transactionId] : null;

            return transactionStatusData;
        },
        transactionStatus() {
            let transactionStatus = this.transactionStatusData
                && this.transactionStatusData.hasOwnProperty('status')
                ? this.transactionStatusData.status : null;

            return transactionStatus;
        },
        transactionDataStatus() {
            let transactionDataStatus = this.transactionStatusData
                && this.transactionStatusData.hasOwnProperty('transactionData')
                && this.transactionStatusData.transactionData.hasOwnProperty('status')
                    ? this.transactionStatusData.transactionData.status : null;

            return transactionDataStatus;
        },
        transactionDataStatusMessage() {
            let transactionDataStatusMessage = this.transactionStatusData
                && this.transactionStatusData.hasOwnProperty('transactionData')
                && this.transactionStatusData.transactionData.hasOwnProperty('comment')
                    ? this.transactionStatusData.transactionData.comment : null;

            return transactionDataStatusMessage;
        }
    },
    created() {
        if (this.transactionStatus > 1 && this.transactionStatus != 5) {
            this.startTransactionDataCheckingProcess();
        } else {
            this.startStatusCheckingProcess();
        }
    },
    watch: {
        checkTransactionStatusInterval(checkTransactionStatusInterval) {
            let show_loading = !!checkTransactionStatusInterval || !!this.checkTransactionDataInterval || this.transactionStatus == 3 && !this.transactionReceiptData;

            this.loading = show_loading;
        },
        checkTransactionDataInterval(checkTransactionStatusInterval) {
            let show_loading = !!checkTransactionStatusInterval || !!this.checkTransactionStatusInterval || this.transactionStatus == 3 && !this.transactionReceiptData;

            this.loading = show_loading;
        },
        transactionReceiptData(transactionReceiptData) {
            let show_loading = !!this.checkTransactionStatusInterval || !!this.checkTransactionDataInterval || this.transactionStatus == 3 && !transactionReceiptData;

            this.loading = show_loading;
        }
    },
    methods: {
        ...mapActions('game_card_payment', {
            getTransactionReceipt: 'getTransactionReceipt',
            checkTransactionStatus: 'checkTransactionStatus'
        }),
        getApiURL() {
            return appConfig.apiUrl;
        },
        printReceipt() {
            window.print();
        },
        formatNumber(number) {
            let numberFormatObject = new Intl.NumberFormat('en-US');
            return numberFormatObject.format(number);
        },
        startStatusCheckingProcess() {
            let obj = this;
            if (obj.checkTransactionStatusInterval || !obj.transactionId
                || this.transactionStatus && obj.transactionStatus > 1 && obj.transactionStatus != 5) {
                return;
            }

            obj.checkTransactionStatusInterval = setInterval(async function () {
                await obj.checkTransactionStatus({'transaction_id': obj.transactionId, 'phoneNumber': obj.phoneNumber}).then(
                    result_data => {
                        if (obj.transactionStatus > 1 && obj.transactionStatus != 5) {
                            obj.clearCheckTransactionStatusInterval();
                            obj.startTransactionDataCheckingProcess();
                        }
                    },
                    error => {
                        obj.clearCheckTransactionStatusInterval();
                        alert('Error!');
                    }
                )
                    .catch(error => {
                        obj.clearCheckTransactionStatusInterval();
                        alert('Error!');
                    })

            }, 3000);
        },
        startTransactionDataCheckingProcess() {
            let obj = this;
            if (obj.checkTransactionDataInterval
                || obj.transactionReceiptData && obj.transactionReceiptData.external_code) {
                return;
            }

            obj.checkTransactionDataInterval = setInterval(async function () {
                await obj.getTransactionReceipt({'transaction_id': obj.transactionId, 'phoneNumber': obj.phoneNumber}).then(
                    result_data => {
                        if (obj.transactionStatus != 3 || obj.transactionReceiptData && obj.transactionReceiptData.external_code) {
                            obj.clearCheckTransactionDataInterval();
                        }
                    },
                    error => {
                        obj.clearCheckTransactionDataInterval();
                        alert('Error!');
                    }
                )
                    .catch(error => {
                        obj.clearCheckTransactionDataInterval();
                        alert('Error!');
                    })

            }, 3000);
        },
        clearCheckTransactionStatusInterval() {
            clearInterval(this.checkTransactionStatusInterval);
            this.checkTransactionStatusInterval = null;
        },
        clearCheckTransactionDataInterval() {
            clearInterval(this.checkTransactionDataInterval);
            this.checkTransactionDataInterval = null;
        }
    },
    updated() {},
    beforeRouteLeave (to, from , next) {
        if (this.checkTransactionStatusInterval) {
            const answer = window.confirm('Do you really want to leave? Payment in progress, you may lose progress!');
            if (answer) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    }
}
</script>

<style>
</style>